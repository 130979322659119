<template>
  <v-list-item :title="order.score">
    <v-list-item-title v-for="(title, i) in titles" :key="i">
      <span v-if="title.label">
        {{ title.label }}:
      </span>
      <span v-if="title.highlight">
        <span
          v-for="(chunk, j) in title.highlight"
          :key="j"
          :class="{ 'es-highlight': chunk.highlight }"
          v-text="chunk.value"
        />
      </span>
      <span v-else v-text="title.value" />
    </v-list-item-title>
    <v-list-item-subtitle v-for="item in content" :key="item.label">
      {{ item.label }}:
      <span v-if="item.highlight">
        <span
          v-for="(chunk, i) in item.highlight"
          :key="i"
          :class="{ 'es-highlight': chunk.highlight }"
          v-text="chunk.value"
        />
      </span>
      <span v-else v-text="item.value" />
    </v-list-item-subtitle>
    <v-list-item-subtitle>
      Date Created: {{ dateCreated }}
    </v-list-item-subtitle>
  </v-list-item>
</template>

<script>
import { dateYear } from '~/models/filters'

/*
  <    match "<"
  h    match "h"
  >    match ">"
  .    match any character
   *   match 0 or more of the preceeding character
   ?   match as few chracters as possible
  <    match "<"
  \/   match "/"
  h    match "h"
  >    match ">"
*/
const HIGHLIGHT_REGEX = /(<h>.*?<\/h>)/

export default {
  name: 'order-search-item',
  props: {
    order: {
      type: Object,
      required: true
    }
  },
  computed: {
    content() {
      const content = [
        { label: 'Company', value: this.order.company_name, highlight: this.getFieldHighlight('company_name') },
        {
          label: 'Project',
          value: `${this.order.project_number} - ${this.order.project_name}`,
          highlight: this.projectHighlight,
        },
        { label: 'Owner', value: this.order.owner, highlight: this.getFieldHighlight('owner') },
      ]

      if (this.order.supplier_name) {
        content.push({
          label: 'Supplier',
          value: this.order.supplier_name,
          highlight: this.getFieldHighlight('supplier_name'),
        })
      }

      return content
    },
    dateCreated() {
      return dateYear(this.order.date_created)
    },
    projectHighlight() {
      if (this.order.highlight.project_number || this.order.highlight.project_name) {
        const projectDetails = this.getFieldHighlight('project_number') || [{ value: this.order.project_number }]

        projectDetails.push({ value: ' - ' })

        return projectDetails.concat(this.getFieldHighlight('project_name') || [{ value: this.order.project_name }])
      }
      return undefined
    },
    titles() {
      const titles = [
        { value: this.order.reference_name, highlight: this.getFieldHighlight('reference_name') },
        {
          label: 'QTM Reference',
          value: this.order.qtm_reference_number,
          highlight: this.getFieldHighlight('qtm_reference_number'),
        },
      ]

      if (this.order.po_number) {
        titles.push({ label: 'PO', value: this.order.po_number, highlight: this.getFieldHighlight('po_number') })
      }

      return titles
    }
  },
  methods: {
    getFieldHighlight(field) {
      if (this.order.highlight[field]) {
        return this.order.highlight[field][0]
          .split(HIGHLIGHT_REGEX)
          .map(chunk => ({
            value: chunk.replace('<h>', '').replace('</h>', ''),
            highlight: HIGHLIGHT_REGEX.test(chunk),
          }))
      }
      return undefined
    },
  }
}
</script>

<style lang="scss" scoped>
.es-highlight {
  background-color: rgb(var(--v-theme-light-teal));
}
</style>
